









































































import Vue from 'vue';
import { Notice, NoticeType } from '@/domain/notice/model/Notice';
import { Table, TableColumn } from 'element-ui';
import { NoticeService } from '@/domain/notice/service/NoticeService';
import { apolloClient } from '@/apolloClient';
import { isHostAdmin } from '@/env';

const noticeService = new NoticeService(apolloClient);
const EVENT = 'EVENT';
const NOTICE = 'NOTICE';

export default Vue.extend({
  name: 'NoticeTableForm',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    notices: {
      type: Array,
      default: () => [],
    },
    typeList: Array,
    forHostOnly: Boolean,
  },
  methods: {
    isHostAdmin,
    async deleteNotice(row: Notice): Promise<void> {
      if (!row.id) {
        this.$modal.show({
          title: '공지사항 삭제 실패!',
          type: 'warning',
          message: '공지사항을 삭제 할 수 없습니다.',
        });
      }

      this.$modal.show(
        {
          title: '공지사항 삭제!',
          type: 'info',
          message: '공지사항을 삭제하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await noticeService.deleteNotice(row.id);

            this.$modal.show(
              {
                title: '공지사항 삭제 성공!',
                type: 'success',
              },
              () => {
                location.reload();
              }
            );
          } catch (err) {
            console.log('err =>', err);
            this.$modal.show({
              title: '공지사항 삭제 실패!',
              type: 'warning',
              message: '공지사항을 삭제 할 수 없습니다.',
            });
          }
        }
      );
    },
    detailNotice(row: Notice): void {
      const params = {
        id: row.id.toString(),
        forHostOnly: this.forHostOnly.toString(),
      };

      this.$router.push({
        name: 'NoticeDetail',
        params: params,
      });
    },
    detailHostNotice(row: Notice): void {
      const params = {
        id: row.id.toString(),
        title: row.title.toString(),
        content: row.content.toString(),
      };

      this.$router.push({
        name: 'HostNoticeDetail',
        params: params,
      });
    },
    convertToTypeName(type: string): string {
      return NoticeType[type];
    },
    checkedType(type: string): string {
      switch (true) {
        case type.includes(NOTICE):
          return 'primary';

        case type.includes(EVENT):
          return 'success';

        default:
          return 'danger';
      }
    },
  },
});
