


























import Vue from 'vue';

export default Vue.extend({
  name: 'NoticeTabs',
  components: {},
  props: {
    typeList: Array,
    typeValue: String,
  },
  methods: {
    moveTab(type: string): void {
      this.$emit('moveTab', type);
    },
  },
});
